import { Dispatch } from 'redux';
import LocalStorageUtils from '../../../utils/local-storage';
import Errors from '../../../shared/validation/errors';
import setLoadingStatus from '../setLoadingStatus';
import headersBuilder, { Method } from '../../../utils/request';
import { Master } from '../../../types/masterTypes';
import { PaginationInfo } from '../../../types/sharedTypes';
import { PAMMState } from '../../reducers/rootReducer';
import { AccountType } from '../../../types/authTypes';

export const FETCH_MASTERS = 'FETCH_MASTERS';
export const FETCH_MASTERS_DEFAULT = 'FETCH_MASTERS_DEFAULT';

export type FetchMastersAction = {
  type: string;
  masters: Array<Master>;
  paginationInfo: PaginationInfo;
};

export const fetchMasters = (
  masters: Array<Master>,
  paginationInfo: PaginationInfo
): FetchMastersAction => {
  return {
    type: FETCH_MASTERS,
    masters,
    paginationInfo
  };
};

// masters default details on investors pages
export type FetchMastersDefaultAction = {
  type: string;
  mastersDefault: Array<Master>;
  paginationInfo: PaginationInfo;
};

export const fetchMastersDefault = (
  mastersDefault: Array<Master>,
  paginationInfo: PaginationInfo
): FetchMastersDefaultAction => {
  return {
    type: FETCH_MASTERS_DEFAULT,
    mastersDefault,
    paginationInfo
  };
};

// TODO переделать получение инфы о пользователе
export const getInfoMastersThunkAC =
  () =>
  async (dispatch: Dispatch, getState: () => PAMMState): Promise<void> => {
    const { api } = getState().configReducer;
    const userLS = LocalStorageUtils.getValueFromLocalStorage('user');
    const userInfoAtLSJson = userLS ? JSON.parse(userLS) : {};

    // TODO исправить бек на инвесторе Value на MM Values
    const generateAccountOptions = () => {
      switch (userInfoAtLSJson.AccountType) {
        case AccountType.Master:
          return 'returnAccountOptionsValues=true';

        case AccountType.Investor:
          return 'returnAccountOptionsValue=true';

        default:
          return '';
      }
    };

    const generateFetchRoute = () => {
      switch (userInfoAtLSJson.AccountType) {
        case AccountType.Master:
          return 'masters';

        case AccountType.Admin:
          return 'admins';

        case AccountType.Investor:
          return 'investors';

        default:
          return '';
      }
    };
    if (userInfoAtLSJson.AccountType !== AccountType.Admin) {
      try {
        await dispatch(setLoadingStatus(true));
        const response = await fetch(
          `${api}/${generateFetchRoute()}/${userInfoAtLSJson?.Id}?${generateAccountOptions()}`,
          headersBuilder(Method.GET)
        );
        const userData = await response.json();
        const updateUser = {
          ...userInfoAtLSJson,
          AccountInfo: userData
        };
        LocalStorageUtils.putValueToLocalStorage('user', JSON.stringify(updateUser));
        dispatch(setLoadingStatus(false));
      } catch (e) {
        Errors.throwErrorMessage(e);
      }
    }
  };

function fetchMastersAsync(
  api: string,
  showInactive: boolean,
  showTest: boolean,
  hideNegativeProfitMasters: boolean | null,
  page?: number,
  limit?: number,
  query?: string,
  sortField?: string,
  direction?: string,
  returnAccountOptionsValues?: boolean | null
): any {
  let queryString = `?page=${page}`;

  if (hideNegativeProfitMasters) {
    queryString += '&hideNegativeProfitMasters=true';
  } else {
    queryString += '&hideNegativeProfitMasters=false';
  }
  if (limit) queryString += `&limit=${limit}`;
  if (query) queryString += `&query=${query}`;
  if (sortField) queryString += `&sortField=${sortField}`;
  if (direction) queryString += `&direction=${direction}`;
  if (returnAccountOptionsValues) queryString += `&returnAccountOptionsValues=${returnAccountOptionsValues}`;
  if (showInactive && showTest) {
    queryString += '&filter=disabled,test';
  } else if (showInactive) {
    queryString += '&filter=disabled';
  } else if (showTest) {
    queryString += '&filter=test';
  }

  if (!LocalStorageUtils.localStorageKeyExists('masters')) {
    LocalStorageUtils.putValuesToLocalStorage('masters', [
      'id=true',
      'login=true',
      'name=true',
      'currency=true',
      'balance=true',
      'credit=true',
      'equity=true',
      'profit=true',
      'margin=true',
      'ownFunds=true',
      'connect=true'
    ]);
  }

  if (!LocalStorageUtils.localStorageKeyExists('showInactiveMasters')) {
    LocalStorageUtils.putValueToLocalStorage('showInactiveMasters', 'false');
  }

  if (!LocalStorageUtils.localStorageKeyExists('showTestMasters')) {
    LocalStorageUtils.putValueToLocalStorage('showTestMasters', 'false');
  }

  if (!LocalStorageUtils.localStorageKeyExists('hideNegativeProfitMasters')) {
    LocalStorageUtils.putValueToLocalStorage('hideNegativeProfitMasters', 'false');
  }

  return (dispatch: Dispatch) => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/masters${queryString}`, headersBuilder(Method.GET))
      .then((response: Response) => Errors.checkResponseForErrors(response, api))
      .then((data: any) => {
        if (returnAccountOptionsValues) {
          dispatch(fetchMastersDefault(data.Data, data.PaginationInfo))
        } else {
            dispatch(fetchMasters(data.Data, data.PaginationInfo))
        }
      })
      .catch((error: Error) => Errors.throwErrorMessage(error))
      .finally(() => dispatch(setLoadingStatus(false)));
  };
}

export default fetchMastersAsync;
