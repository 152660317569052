import { FETCH_MONITORING_ACTION, MonitoringAction } from '../../../components/administrator/monitoring/fetchMonitoring';
import { IInvestorsMassAttach } from '../../../types/investorTypes';
import { IBalanceOperation, ILoadOrders, IMonitoringResponse, IOrdersCompare, IOrdersRedistribute, IOrdersReload, IParametersToCheck, IParametersToFix, IPerformanceFeeCharge, ISendReport } from '../../../types/maintenanceTypes';
import { FETCH_MASS_ATTACH, MassAtachAction } from '../../actions/investor/massAttachDetachInvestors';
import { AccountParametersAction, FETCH_ACCOUNT_PARAMETERS } from '../../actions/maintenance/fetchAccountsParameters';
import { EditBalanceOperationAction, FETCH_EDIT_BALANCE_OPERATION } from '../../actions/maintenance/fetchEditBalanceOperation';
import { FETCH_LOAD_ORDERS, LoadOrdersAction } from '../../actions/maintenance/fetchLoadOrdersByTickets';
import { FETCH_ORDERS_COMPARE, OrdersCompareAction } from '../../actions/maintenance/fetchOrdersCompare';
import { FETCH_ORDERS_REDISTRIBUTE, OrdersRedistributeAction } from '../../actions/maintenance/fetchOrdersRedistribute';
import { FETCH_ORDERS_RELOAD, OrdersReloadAction } from '../../actions/maintenance/fetchOrdersReload';
import { FETCH_PERFORMANCE_FEE_CHARGE, PerformanceFeeChargeAction } from '../../actions/maintenance/fetchPerformanceFeeCharge';
import { FETCH_SEND_REPORT, SendReportAction } from '../../actions/maintenance/fetchSendReport';

export interface SendReportState {
  sendReport: ISendReport;
  loadOrders: ILoadOrders;
  ordersReload: IOrdersReload;
  ordersCompare: IOrdersCompare;
  balanceOperation: IBalanceOperation;
  ordersRedistribute: IOrdersRedistribute;
  parametersAccounts: IParametersToCheck | IParametersToFix;
  performanceFeeCharge: IPerformanceFeeCharge;
  parameters: IMonitoringResponse;
  massAttach: IInvestorsMassAttach;
}

const initialState: SendReportState = {
  sendReport: {} as ISendReport,
  loadOrders: {} as ILoadOrders,
  ordersReload: {} as IOrdersReload,
  ordersCompare: {} as IOrdersCompare,
  balanceOperation: {} as IBalanceOperation,
  ordersRedistribute: {} as IOrdersRedistribute,
  parametersAccounts: {} as IParametersToCheck | IParametersToFix,
  performanceFeeCharge: {} as IPerformanceFeeCharge,
  parameters: {} as IMonitoringResponse,
  massAttach: {} as IInvestorsMassAttach
}


type RequestAction = SendReportAction
  & LoadOrdersAction
  & OrdersReloadAction
  & OrdersCompareAction
  & EditBalanceOperationAction
  & OrdersRedistributeAction
  & AccountParametersAction
  & PerformanceFeeChargeAction
  & MonitoringAction
  & MassAtachAction

const reportReducer = (
  state: SendReportState = initialState,
  action: RequestAction
): SendReportState => {
  switch (action.type) {
    case FETCH_SEND_REPORT:
      return {
        ...state,
        sendReport: action.sendReport
      }
    case FETCH_LOAD_ORDERS:
      return {
        ...state,
        loadOrders: action.loadOrders
      };
    case FETCH_ORDERS_RELOAD:
      return {
        ...state,
        ordersReload: action.ordersReload
      };
    case FETCH_ORDERS_COMPARE:
      return {
        ...state,
        ordersCompare: action.ordersCompare
      };
    case FETCH_EDIT_BALANCE_OPERATION:
      return {
        ...state,
        balanceOperation: action.balanceOperation
      };
    case FETCH_ORDERS_REDISTRIBUTE:
      return {
        ...state,
        ordersRedistribute: action.ordersRedistribute
      };
    case FETCH_ACCOUNT_PARAMETERS:
      return {
        ...state,
        parametersAccounts: action.parametersAccounts
      };
    case FETCH_PERFORMANCE_FEE_CHARGE:
      return {
        ...state,
        performanceFeeCharge: action.performanceFeeCharge
      };
    case FETCH_MONITORING_ACTION:
      return {
        ...state,
        parameters: action.parameters
      };
    case FETCH_MASS_ATTACH:
      return {
        ...state,
        massAttach: action.massAttach
      };
    default:
      return state;
  }
};

export default reportReducer;
