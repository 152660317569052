import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Modal, Form, Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { UpdateInvestor, Investor, InvestorMode } from '../../../../types/investorTypes';
import updateInvestor from '../../../../redux/actions/investor/updateInvestor';
import { PAMMState } from '../../../../redux/reducers/rootReducer';
import SimpleInput from '../../../shared/SimpleInput';
import InputUtils from '../../../../utils/validate-schema';
import { AccountType } from '../../../../types/authTypes';
import LocalStorageUtils from '../../../../utils/local-storage';

interface InvestorEditModalProps {
  investor: Investor;
  show: boolean;
  toggleVisibility(modal: string): void;
}

const InvestorEditModal: React.FC<InvestorEditModalProps> = ({ investor, show, toggleVisibility }): JSX.Element => {
  const inputStyle = {
    width: '15rem'
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const api: string = useSelector((state: PAMMState) => state.configReducer.api);

  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  const [user, setUser] = React.useState<any>();

  React.useEffect(() => {
    if (typeof userJSON === 'string') {
      setUser(JSON.parse(userJSON));
    }
  }, [userJSON]);

  const validatorRequiredKey = 'validators.error.required';
  const editInvestorSchema = {
    Username: Yup.string().required(intl.formatMessage({ id: validatorRequiredKey })).max(100, intl.formatMessage({ id: 'validators.error.name' })).matches(/^[A-Za-z0-9_.@-]+?$/, intl.formatMessage({ id: 'validators.error.username' })),
    Password: Yup.string().test('len', intl.formatMessage({ id: 'validators.error.password' }), val => !val || val.length === 0 || val.length >= 6),
    Name: Yup.string().required(intl.formatMessage({ id: validatorRequiredKey })).matches(/^([\p{L}\p{N}\p{P}\p{S}]+ )+[\p{L}\p{N}\p{P}\p{S}]+$|^[\p{L}\p{N}\p{P}\p{S}]+$/u, intl.formatMessage({ id: 'validators.error.invalidName' })),
    Email: Yup.string().required(intl.formatMessage({ id: validatorRequiredKey })).matches(/^[A-Za-zА-Яа-я0-9._%+-]+@[A-Za-zА-Яа-я0-9._%+-]+\.[A-Za-zА-Яа-я0-9]{1,100}$/, intl.formatMessage({ id: 'validators.error.email' })),
    Phone: Yup.string()
      .max(100, intl.formatMessage({ id: 'validators.error.maxLength' }))
      .matches(
        /^[A-Za-zА-Яа-я0-9\s\(\)\+-]+$/,
        intl.formatMessage({ id: 'validators.error.mustBeLetterNumberDashPlus' })
      )
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    Country: Yup.string()
      .max(100, intl.formatMessage({ id: 'validators.error.maxLength' }))
      .matches(
        /^[A-Za-z- ]+$/,
        intl.formatMessage({ id: 'validators.error.mustBeLetterDashSpace' })
      )
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    LinkToken: Yup.string()
      .matches(
        /^[A-Za-zА-Яа-я0-9_-]+$/,
        intl.formatMessage({ id: 'validators.error.mustBeLetterNumberDashUnderscore' })
      )
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    HighWaterMark: Yup.string() // .matches(/^([0-9]+(\.\d{1,2})?)$/)
  }

  const originalInvestor: Investor = {
    ...investor,
    Password: ''
  };

  const [editInvestorForm, setEditInvestorForm] = React.useState<Investor>(investor);

  React.useEffect(() => {
    setEditInvestorForm(investor);
  }, [investor]);

  const handleCancel = (): void => {
    setEditInvestorForm(originalInvestor);
    toggleVisibility('EditUser');
  };

  const handleSubmit = (): void => {
    const request: UpdateInvestor = {
      Username: editInvestorForm.Username,
      Name: editInvestorForm.Name,
      Password: editInvestorForm.Password,
      Email: editInvestorForm.Email,
      Country: editInvestorForm.Country,
      Phone: editInvestorForm.Phone,
      LinkToken: editInvestorForm.LinkToken,
      // Mode: InvestorMode.Normal,
      HighWaterMark: editInvestorForm.HighWaterMark
    };

    if (user?.AccountType === AccountType.Investor || investor.Mode === Number(request.Mode)) {
      delete request.Mode;
    }
    if (investor.Username === request.Username) {
      delete request.Username;
    }
    if (investor.Name === request.Name) {
      delete request.Name;
    }
    if (investor.Password === request.Password) {
      delete request.Password;
    }
    if (investor.Email === request.Email) {
      delete request.Email;
    }
    if (investor.Country === request.Country) {
      delete request.Country;
    }
    if (investor.Phone === request.Phone) {
      delete request.Phone;
    }
    if (investor.LinkToken === request.LinkToken) {
      delete request.LinkToken;
    }
    if (investor.HighWaterMark === request.HighWaterMark) {
      delete request.HighWaterMark;
    }

    if (JSON.stringify(request)[2] === undefined) {
      return
    }

    dispatch(updateInvestor(api, editInvestorForm.Id.toString(), request));
    toggleVisibility('EditUser');
  };

  const isUpdateDisabled = InputUtils.validateSchema(Yup.object().shape(editInvestorSchema), editInvestorForm);

  return (
    <Modal show={show} onHide={handleCancel} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="investorDetails.modal.editInvestor.editInvestor" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="editUser">
        <Form>
          <SimpleInput
            state={editInvestorForm}
            valueKey="Username"
            labelTranslateKey="investorDetails.modal.editInvestor.username.label"
            schema={editInvestorSchema.Username}
            type="text"
            setState={setEditInvestorForm}
            placeholder="investorDetails.modal.editInvestor.username.placeholder"
            style={inputStyle}
            className="mx-auto"
            size="sm"
          />

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Name"
              labelTranslateKey="investorDetails.modal.editInvestor.name.label"
              schema={editInvestorSchema.Name}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.name.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Password"
              labelTranslateKey="investorDetails.modal.editInvestor.password.label"
              schema={editInvestorSchema.Password}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.password.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Email"
              labelTranslateKey="investorDetails.modal.editInvestor.email.label"
              schema={editInvestorSchema.Email}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.email.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Phone"
              labelTranslateKey="investorDetails.modal.editInvestor.phone.label"
              schema={editInvestorSchema.Phone}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.phone.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          <div>
            <SimpleInput
              state={editInvestorForm}
              valueKey="Country"
              labelTranslateKey="investorDetails.modal.editInvestor.country.label"
              schema={editInvestorSchema.Country}
              type="text"
              setState={setEditInvestorForm}
              placeholder="investorDetails.modal.editInvestor.country.placeholder"
              style={inputStyle}
              className="mx-auto"
              size="sm"
            />
          </div>

          {user?.AccountType === AccountType.Admin
            ? (
              <div>
                <SimpleInput
                  state={editInvestorForm}
                  valueKey="LinkToken"
                  labelTranslateKey="investorDetails.modal.editInvestor.linkToken.label"
                  schema={editInvestorSchema.LinkToken}
                  type="text"
                  setState={setEditInvestorForm}
                  placeholder="investorDetails.modal.editInvestor.linkToken.placeholder"
                  style={inputStyle}
                  className="mx-auto"
                  size="sm"
                />
              </div>
            ) : null}

          {user?.AccountType === AccountType.Admin
            ? (
              <div>
                <SimpleInput
                  state={editInvestorForm}
                  valueKey="HighWaterMark"
                  labelTranslateKey="investorDetails.modal.editInvestor.highwatermark.label"
                  schema={editInvestorSchema.HighWaterMark}
                  type="text"
                  setState={setEditInvestorForm}
                  placeholder="investorDetails.modal.editInvestor.highwatermark.placeholder"
                  style={inputStyle}
                  className="mx-auto"
                  size="sm"
                />
              </div>
            ) : null}
          {/* <Form.Label className="mt-4 mb-0">
            <span className='text-danger'>*</span>
            <FormattedMessage id="createAccount.admin.adminInformation.requiredFields" />
          </Form.Label> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => handleCancel()}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => handleSubmit()}
          disabled={isUpdateDisabled}
        >
          <FormattedMessage id="button.update" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvestorEditModal;
