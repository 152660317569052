import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import headersBuilder, { Method } from '../../../utils/request';
import { GraphicsPeriodTypes } from '../../../types/masterTypes';
import { Summary, SummaryType } from '../../../types/sharedTypes';
import { getFromDateForRequest, getToDateForRequest, timeConverter } from '../../../utils/date-time';
import { setLoadingStatus } from '../../actions/setLoadingStatus';

export const FETCH_DEPOSITS = 'FETCH_DEPOSIT';
export const FETCH_INVESTMENTS = 'FETCH_INVESTMENTS';
export const FETCH_PROFIT = 'FETCH_PROFIT';
export const FETCH_PROFIT_PER_PERIOD = 'FETCH_PROFIT_PER_PERIOD';
export const FETCH_VOLUMES = 'FETCH_VOLUMES';
export const FETCH_INVESTORS_SUMMARY = 'FETCH_INVESTORS_SUMMARY';

const getSummaryActionType = (summaryType: SummaryType): string => {
  switch (summaryType) {
    case SummaryType.Deposits:
      return FETCH_DEPOSITS;
    case SummaryType.Investments:
      return FETCH_INVESTMENTS;
    case SummaryType.Profit:
      return FETCH_PROFIT;
    case SummaryType.Volumes:
      return FETCH_VOLUMES;
    case SummaryType.Investors:
      return FETCH_INVESTORS_SUMMARY;
    default:
      return '';
  }
}

export type FetchSummaryAction = {
  type: string;
  summary: Array<Summary>;
};

const fetchSummary = (summary: Array<Summary>, summaryType: string): FetchSummaryAction => {
  return {
    type: summaryType,
    summary
  };
};

const fetchSummaryAsync = (api: string, period: GraphicsPeriodTypes, summaryType: SummaryType, dateFrom: Date, dateTo: Date, id?: number, calcMethod?: string) => {
  let base = '';
  let query = '';

  // const dateTimeDateFrom = timeConverter(Math.round(dateFrom.getTime() * 1000));
  // const dateTimeDateTo = timeConverter(Math.round(dateTo.getTime() * 1000));
  const dateTimeDateFrom = getFromDateForRequest(dateFrom);
  const dateTimeDateTo = getToDateForRequest(dateTo);

  if (id) {
    base = `/${id}?period=${period}`;
  } else {
    base = `?period=${period}`;
  }

  if (dateFrom) query += `&from=${dateTimeDateFrom}`;
  if (dateTo) query += `&to=${dateTimeDateTo}`;
  if (calcMethod) query += `&calcMethod=${calcMethod}`;

  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));

    fetch(`${api}/summary/${summaryType}${base}${query}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => dispatch(fetchSummary(data, getSummaryActionType(summaryType))))
    .catch((error: Error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export default fetchSummaryAsync;
