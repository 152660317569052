import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { AppNavbar, AppSideBar, IconItem } from '@t4b/core';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { History, Location } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Nav, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faUsers, faUser, faCogs, faUserPlus, faBook, faChartArea, faExchangeAlt, faList, faUserTie, faPercent, faHandHoldingDollar, faBullseye, faDesktop, faNetworkWired, faFileLines} from '@fortawesome/free-solid-svg-icons';
import GenUserNavbar from './components/generalUser/GenUserNavbar';
import { APP_LOGO } from './const/logo.const';
import { DEFAULT_APP_LANGS } from './const/langs.const';
import LocalStorageUtils from './utils/local-storage';
import { getJwt, loginUserAsync, logoutUserAsync, removeJwt } from './redux/actions/auth';
import fetchConfigAsync, { fetchLanguageJSONAsync } from './redux/actions/shared/fetchConfig';
import AppRoutes from './components/routes/Routes';
// import isTokenExpired from './utils/token';
import useWindowResize from './hooks/useWindowSize';
import { mobileWidth } from './const/size.const';
import BottomBarMobile from './components/mobile/BottomBarMobile';
import { PAMMState } from './redux/reducers/rootReducer';
import OperationsMenuMobile from './components/mobile/OperationsMenuMobile';
import LinkedAccountsMenu from './components/mobile/LinkedAccountsMenuMobile';
import LanguagesMenu from './components/mobile/LanguagesMenuMobile';
import ConfirmConnectToMasterMenu from './components/mobile/ConfirmConnectToMasterMenuMobile';
import { setOpsMenuStatus, setLinkedAccountsMenuStatus, setLanguagesMenuStatus, setConnectToMasterConfirmationStatus, setDisconnectFromMasterConfirmationStatus } from './redux/actions/setMobileMenusStatus';
import { Investor } from './types/investorTypes';
import { AccountType } from './types/authTypes';
import { getOperationsMenuHeight } from './utils/operationsMenuMobile';
import ConfirmDisconnectFromMasterMenu from './components/mobile/ConfirmDisconnectFromMasterMenuMobile';
import { fetchInvestors } from './redux/actions/investor/fetchInvestors';
import getLinkedAccountsMenuMobileHeight from './utils/linkedAccountsMenuMobile';
import { shadowBox } from './styles/styles';

const App: React.FC = () => {
  const dispatch: any = useDispatch();
  const history: History = useHistory();
  const location: Location = useLocation();
  const [width, height] = useWindowResize();

  const langJSON = useSelector((state: PAMMState) => state.configReducer.langFile);

  // START "AppNavbar" related
  const api = useSelector((state: PAMMState) => state.configReducer.api);
  const [currentLang, setLang] = React.useState<string>(LocalStorageUtils.getValueFromLocalStorage('lang') as string);
  const languagesList = useSelector((state: PAMMState) => state.configReducer.languages);
  const copyright: string = useSelector((state: PAMMState) => state.configReducer.copyright);
  const defaultLanguage: string = useSelector((state: PAMMState) => state.configReducer.defaultLanguage);
  
  const [sidebarHidden, setSidebarHidden] = React.useState<boolean>(true);
  // const { isLoading } = useSelector((state: any) => state.loadingStatusReducer);
  // const isAuthenticated = useSelector((store: any) => store.authReducer.isAuthenticated);
  const isAuthenticated = LocalStorageUtils.getValueFromLocalStorage('isAuthenticated') === 'true';

  const [tokenState, setTokenState] = React.useState(false);
  // const tokenExpired: boolean = isTokenExpired();
  const handleSidebarClick = () => setSidebarHidden(!sidebarHidden);
  const [showMobileVersion, setShowMobileVersion] = React.useState(width < mobileWidth);
  const userJSON: string | null = LocalStorageUtils.getValueFromLocalStorage('user');

  // this shouldn't be like this
  const investors: Array<Investor> = useSelector((state: PAMMState) => state.investorsReducer.investors);
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);

  let user: any;

  if (typeof userJSON === 'string') {
    user = JSON.parse(userJSON);
  }

  React.useEffect(() => {
    if(defaultLanguage) {
      dispatch(fetchLanguageJSONAsync(currentLang || defaultLanguage));
    }
  }, [currentLang, defaultLanguage]);

  React.useEffect(() => {
    setShowMobileVersion(width < mobileWidth);
  }, [width, height]);

  const isTokenExpired = (): any => {
    if (Number(LocalStorageUtils.getValueFromLocalStorage('exp')) < Date.now()) {
      setTimeout(() =>{
        setTokenState(true);
        isTokenExpired();
      }, 360000)
      return true;
    }

      setTimeout(() =>{
       setTokenState(false);
       isTokenExpired();
      }, 360000)
      return false;
  }

  const tokenExpired: boolean = isTokenExpired();
  React.useEffect(() => {
    if (isAuthenticated && tokenExpired) {
      dispatch(logoutUserAsync(api, history, true));
    }
  }, [tokenState]);

  React.useEffect(() => {
    dispatch(fetchConfigAsync());
  }, [dispatch]);

  const onLangChange = (langKey: string): void => {
    setLang(langKey);
    LocalStorageUtils.putValueToLocalStorage('lang', langKey);
    dispatch(fetchLanguageJSONAsync(langKey));

    if (showMobileVersion) {
      dispatch(setLanguagesMenuStatus(false));
    }
  };

  const signOutFunc = () => {
    dispatch(logoutUserAsync(api, history));
    removeJwt();
  };

  const exitButton: JSX.Element = (
    <Nav.Link key="exitBtn" className="pamm-navbar-exit pl-0" onClick={signOutFunc} data-testid="navbar-exit">
      <FontAwesomeIcon size="lg" color="white" icon={faSignOutAlt} />
    </Nav.Link>
  );
  // END

  // START "AppLoginPage" related
  const signInFunc = (username: string, password: string, rememberMe: boolean) => {
    dispatch(loginUserAsync(api, username, password, rememberMe, history));
  };
  // END

  // START "AppSideBar" related
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const sidebarItems = [
    {
      translateKey: 'dashboard',
      isExternal: false,
      link: '/dashboard',
      icon: new IconItem('48px', faChartArea, 'lg', '10px', '#43A09A'),
      tooltipKey: <FormattedMessage id='dashboard'/>
    },
    {
      translateKey: 'investors.title',
      isExternal: false,
      link: '/investors',
      icon: new IconItem('48px', faUsers, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id='investors.title'/>
    },
    {
      translateKey: 'masters.title',
      isExternal: false,
      link: '/money_managers',
      icon: new IconItem('48px', faHandHoldingDollar, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id='masters.title'/>
    },
    {
      translateKey: 'admins',
      isExternal: false,
      link: '/admins',
      icon: new IconItem('48px', faUserTie, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id='admins'/>
    },
    {
      translateKey: 'feeAccounts.title',
      isExternal: false,
      link: '/fee-accounts',
      icon: new IconItem('48px', faPercent, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id='feeAccounts.title'/>
    },
    {
      translateKey: 'investors.report',
      isExternal: false,
      link: '/investorsReport',
      icon: new IconItem('48px', faFileLines, 'lg', '10px', '#7BD6CF'),
      tooltipKey: <FormattedMessage id='investors.report'/>
    },
    {
      translateKey: 'orders.title',
      isExternal: false,
      link: '/orders',
      icon: new IconItem('48px', faList, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id='orders.title'/>
    },
    {
      translateKey: 'journal',
      isExternal: false,
      link: '/journal',
      icon: new IconItem('48px', faBook, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id='journal'/>
    },
    {
      translateKey: 'requestsNomPlur',
      isExternal: false,
      link: '/requests',
      icon: new IconItem('48px', faExchangeAlt, 'lg', '10px', '#2C869E'),
      tooltipKey: <FormattedMessage id='requestsNomPlur'/>
    },
    {
      translateKey: 'create',
      isExternal: false,
      link: '/create/investor',
      icon: new IconItem('48px', faUserPlus, 'lg', '10px', '#70BBD0'),
      tooltipKey: <FormattedMessage id='create'/>
    },
    {
      translateKey: 'investorsAttachDetach',
      isExternal: false,
      link: '/mass-attach/investors',
      icon: new IconItem('48px', faNetworkWired, 'lg', '10px', '#70BBD0'),
      tooltipKey: <FormattedMessage id='investorsAttachDetach'/>
    },
    {
      translateKey: 'administrator.monitoring',
      isExternal: false,
      link: '/monitoring',
      icon: new IconItem('48px', faDesktop, 'lg', '10px', '#FFFFFF'),
      tooltipKey: <FormattedMessage id='administrator.monitoring'/>
    },
    {
      translateKey: 'settings',
      isExternal: false,
      link: '/settings/common',
      icon: new IconItem('48px', faCogs, 'lg', '10px', '#43A09A'),
      tooltipKey: <FormattedMessage id='settings'/>
    }
  ];

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const licenceModal = (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="licenceModal.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>PAMM Frontend version: 128</p>
        <p>
          {copyright}
        </p>
      </Modal.Body>
    </Modal>
  );

  const genUserCopyrightBar = (
    <Row style={{ ...shadowBox, height: 66 }} className="mb-5 align-items-center p-3">
      <span>
        {copyright}
      </span>
    </Row>
  );

  const sidebarComponent = isAuthenticated && !tokenExpired ? (
    <AppSideBar
      sidebarItems={sidebarItems}
      logo={faBars}
      progressItems={[]}
      sidebarHidden={sidebarHidden}
      currentLocation={location.pathname}
      onLicenceClick={handleShowModal}
      isScrollable
    />
  ) : null;
  // END

  const showOpsMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showOpsMenu);
  const showlinkedAccountsMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showlinkedAccountsMenu);
  const showLanguagesMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showLanguagesMenu);
  const showConfirmConnectToMasterMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showConnectToMasterConfirmation);
  const showConfirmDisconnectFromMasterMenu = useSelector((state: PAMMState) => state.mobileMenusStatusReducer.showDisconnectFromMasterConfirmation);

  let touches: any = [];

  const handleSwipe = (event: any) => {
    touches.push(event.pageY);
  }

  const handleCancel = (menu: string): void => {
    if (touches[0] < touches[touches.length - 1]) {
      if (menu === 'operationsMenu') {
        dispatch(setOpsMenuStatus(false));
      } else if (menu === 'linkedAccountsMenu') {
        dispatch(setLinkedAccountsMenuStatus(false));
      } else if (menu === 'languagesMenu') {
        dispatch(setLanguagesMenuStatus(false));
      } else if (menu === 'confirmConnectToMasterMenu') {
        dispatch(setConnectToMasterConfirmationStatus(false));
      } else if (menu === 'confirmDisconnectFromMasterMenu') {
        dispatch(setDisconnectFromMasterConfirmationStatus(false));
      }
    }

    touches = [];
  }

  const handleCancelSingleTouch = (): void => {
    if (showOpsMenu) {
      dispatch(setOpsMenuStatus(false));
    } else if (showlinkedAccountsMenu) {
      dispatch(setLinkedAccountsMenuStatus(false));
    } else if (showLanguagesMenu) {
      dispatch(setLanguagesMenuStatus(false));
    } else if (showConfirmConnectToMasterMenu) {
      dispatch(setConnectToMasterConfirmationStatus(false));
    } else if (showConfirmDisconnectFromMasterMenu) {
      dispatch(setDisconnectFromMasterConfirmationStatus(false));
    }
  }

  const shadow = (
    // eslint-disable-next-line react/self-closing-comp
    <div className="shadow shadow-active" style={{ zIndex: 5000 }}>
    </div>
  );

  const showSidebar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (user.AccountType !== AccountType.Admin) {
        return false;
      }

      return true;
    }

    return false;
  };

  const showAdminNavbar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (!showMobileVersion && user.AccountType === AccountType.Admin) {
        return true;
      }
      if (showMobileVersion && user.AccountType === AccountType.Admin) {
        return true;
      }
    }

    return false;
  };

  const showGeneralUserNavbar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (!showMobileVersion && user.AccountType !== AccountType.Admin) {
        return true;
      }
    }

    return false;
  };

  const showBottomBar = (): boolean => {
    if (user && isAuthenticated && !tokenExpired) {
      if (showMobileVersion && user.AccountType !== AccountType.Admin) {
        return true;
      }
    }

    return false;
  };

  const getAppStyle = (): string => {
    if ((user && user.AccountType === AccountType.Admin) || !user) {
      return 'm-4 p-2 mt-1';
    }

    return '';
  };

  const overlayscrollbarsOptions = {
    overflowBehavior: {
      x: 'hidden'
    },
    scrollbars: {
      autoHide: 'scroll',
      autoHideDelay: 1000
    }
  } as OverlayScrollbars.Options;

  return api && (Object.keys(langJSON).length !== 0) && defaultLanguage ? (
    <IntlProvider locale={currentLang || defaultLanguage} messages={langJSON}>
      {showAdminNavbar()
      ? (
        <AppNavbar
          logo={APP_LOGO}
          i18nLangs={languagesList}
          currentLangKey={currentLang || defaultLanguage}
          isAuthenticated={isAuthenticated && !tokenExpired}
          onLangChange={onLangChange}
          onSidebarHandlerClick={handleSidebarClick}
          exitButton={exitButton}
        />
      ) : null}
      {showBottomBar() ? <BottomBarMobile user={user} /> : null}
      <div
        className={`d-flex justify-content-center${  sidebarHidden ? '' : ' sidebar-show'}`}
        // className="d-flex justify-content-center"
        onPointerDown={handleCancelSingleTouch}
      >
        {licenceModal}
        {showSidebar() ? sidebarComponent : null}
        {/* eslint-disable-next-line no-nested-ternary */}
        {(showMobileVersion && !user) || (showMobileVersion && user?.AccountType === 'investor') || (showMobileVersion && user?.AccountType === 'master')
        ? (
          <>
            <OverlayScrollbarsComponent
              options={overlayscrollbarsOptions}
              style={{height: 'calc(100vh - 58px)', width: '100%'}}
            >
              <div className="m-4 p-2 mt-1" >
                <AppRoutes
                  isAuthenticated={isAuthenticated && !tokenExpired}
                  signInFunc={signInFunc}
                  history={history}
                />
              </div>
            </OverlayScrollbarsComponent>
            {(showOpsMenu ||
            showlinkedAccountsMenu ||
            showLanguagesMenu ||
            showConfirmConnectToMasterMenu ||
            showConfirmDisconnectFromMasterMenu) ? shadow : null}
          </>
        ) : user?.AccountType === 'admin' ? (
          <OverlayScrollbarsComponent style={{height: 'calc(100vh - 58px)', width: '100%'}}>
            <div className={getAppStyle()} style={{ minWidth: '1146px'}}>
              <AppRoutes
                isAuthenticated={isAuthenticated && !tokenExpired}
                signInFunc={signInFunc}
                history={history}
                currentLang={currentLang}
              />
            </div>
          </OverlayScrollbarsComponent>
        ) : (
          <div style={{ width: '100%', maxWidth: 1615 }}>
            {showGeneralUserNavbar()
            ? (
              <GenUserNavbar
                signOutFunc={signOutFunc}
                currentLang={currentLang || defaultLanguage}
                languages={languagesList}
                onLangChange={onLangChange}
              />
            ) : null}
            <OverlayScrollbarsComponent
              options={overlayscrollbarsOptions}
              style={{height: 'calc(100vh - 70px)', width: '100%'}}
            >
              <AppRoutes
                isAuthenticated={isAuthenticated && !tokenExpired}
                signInFunc={signInFunc}
                history={history}
              />

              {(isAuthenticated && !tokenExpired ? genUserCopyrightBar : null)}
            </OverlayScrollbarsComponent>
          </div>
        )}
      </div>
      <ToastContainer />
      <OperationsMenuMobile
        // eslint-disable-next-line no-nested-ternary
        menuHeight={showOpsMenu ? (user?.AccountType === 'investor' ? getOperationsMenuHeight(user?.AccountType, investor?.AccountOptions) : getOperationsMenuHeight(user?.AccountType, user?.AccountInfo?.AccountOptions)) : 0}
        onSwipe={handleSwipe}
        onCancel={handleCancel}
        currentLang={currentLang || defaultLanguage}
      />
      {investors?.length > 0
      ? (
        <LinkedAccountsMenu
          menuHeight={showlinkedAccountsMenu ? getLinkedAccountsMenuMobileHeight(investors?.length - 1) : 0}
          onSwipe={handleSwipe}
          onCancel={handleCancel}
        />
      ) : null}
      <LanguagesMenu
        menuHeight={showLanguagesMenu ? (690) : 0}
        onSwipe={handleSwipe}
        onCancel={handleCancel}
        onLangChange={onLangChange}
      />
      <ConfirmConnectToMasterMenu
        menuHeight={showConfirmConnectToMasterMenu ? 200 : 0}
        onSwipe={handleSwipe}
        onCancel={handleCancel}
      />
      <ConfirmDisconnectFromMasterMenu
        menuHeight={showConfirmDisconnectFromMasterMenu ? 200 : 0}
        onSwipe={handleSwipe}
        onCancel={handleCancel}
      />
    </IntlProvider>
  ) : null;
};

export default App;
