import { StateToggle, VisibilityToggle } from './toggles';

export class AccountOptionsAdmin {
  EmailNotifications: StateToggle = StateToggle.Off;
  OrdersCommissions: VisibilityToggle = VisibilityToggle.Hide;
}

export class Admin {
  Id = 0;
  Name = '';
  Username = '';
  Email = '';
  ViewOnly = 'false';
  AccountOptions = new AccountOptionsAdmin();
}

export class CreateAdmin {
  ViewOnly = false;
  Name = '';
  Username = '';
  Password = '';
  Email = '';
  // AccountOptions = new AccountOptionsAdmin();
}

export class EditAdminRequest {
  Name = '';
  Username = '';
  Email = '';
  AccountOptions = new AccountOptionsAdmin();
  Password = '';
}

export class EditAdminForm {
  Name = '';
  Username = '';
  Email = '';
  AccountOptions = new AccountOptionsAdmin();
  Password = '';
  ViewOnly = 'false';
}
